










































































export interface IGenericMenu {
	leftHeader: string;
	rightHeader: string;
	leftPropName: string;
	rightPropName: string;
}

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class MenuOverflowDisplay extends Vue {
	show: any = false;
	@Prop() props!: IGenericMenu;
	@Prop() items!: any[];
}
