


























































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

// Component Imports
import LoadingData from '@/components/datadisplay/LoadingData.vue';
import StepperNavigation from '@/components/zones/stepperNavigation.vue';

import MenuOverflowDisplay, {
	IGenericMenu,
} from '@/components/generic/menuOverflowDisplay/menuOverflowDisplay.vue';

import NoData from '@/components/datadisplay/NoData.vue';

// State module imports
import { ZonesModule } from '@/store/zones/zones';
import { AuthStore } from '@/store/auth/auth';

// Service Imports
import { SupplierZoneServicesService } from '@/services/supplierZones/supplierZoneServicesService';
import { ISupplierZonePrice } from '@/classes/supplierZones/ISupplierZonePrice';
import { SupplierZonePrice } from '@/classes/supplierZones/supplierZonePrice';
import { DropDownType } from '@/classes/generics/classes/DropDownType';
import { SupplierFrequencyEnum } from '@/classes/generics/enums/SupplierFrequencyEnum';
import { DaysEnum } from '@/classes/generics/enums/DaysEnum';
import { SupplierZonePriceForm } from '@/classes/supplierZones/forms/supplierZonePriceForm';
import { ContainerWasteStream } from '@/classes/serviceDetails/containerDetails/ContainerWasteStream';
import { ContainerSize } from '@/classes/serviceDetails/containerDetails/ContainerSize';
import { ContainerType } from '@/classes/serviceDetails/containerDetails/ContainerType';
import { ContainerUnit } from '@/classes/serviceDetails/containerDetails/ContainerUnit';
import { ContainerWasteSubStream } from '@/classes/serviceDetails/containerDetails/ContainerWasteSubStream';
import { IContainerWasteStream } from '@/classes/serviceDetails/interfaces/ContainerInfo/IContainerWasteStream';
import { IService } from '@/classes/serviceDetails/interfaces/IService';

@Component({
	components: { StepperNavigation, NoData, MenuOverflowDisplay, LoadingData },
})
export default class ServiceStep extends Vue {
	authModule = AuthStore;
	isLoading = true;

	//Services
	supplierZoneServicesService = new SupplierZoneServicesService();

	//Props
	pricingItem = { ShowModal: false };
	buttonEvent: any;

	// Container for stream filtering
	streamsFiltering: any = {
		WasteStream: new ContainerWasteStream(),
		WasteSubStream: new ContainerWasteSubStream(),
		ContainerType: new ContainerType(),
		ContainerSize: new ContainerSize(),
		ContainerUnit: new ContainerUnit(),
	};

	menuPricingProps: IGenericMenu = {
		leftHeader: 'Type',
		rightHeader: 'Price (£)',
		leftPropName: 'Name',
		rightPropName: 'Value',
	};

	surchargesProps: IGenericMenu = {
		leftHeader: 'Postcode',
		rightHeader: 'Price (£)',
		leftPropName: 'Postcode',
		rightPropName: 'Surcharge',
	};

	newPrices = new SupplierZonePrice();

	selectedService: IService | null = null;
	selectedPrices: ISupplierZonePrice[] = [];

	// Show / Hide Add Service
	showCreateForm = false;

	// Pricing Form Validity
	isServicePricingValid = false;
	supplierZonePriceForm = new SupplierZonePriceForm();

	// Setup drop down type for the select boxes
	frequencies = Object.keys(SupplierFrequencyEnum)
		.filter(xx => Number(xx))
		.map(val => {
			return new DropDownType(SupplierFrequencyEnum[Number(val)], Number(val));
		});

	days = Object.keys(DaysEnum)
		.filter(xx => Number(xx))
		.map(val => {
			return new DropDownType(DaysEnum[Number(val)], Number(val));
		});

	// APi Data
	filteredServices: IService[] = [];
	containerInfo: IContainerWasteStream[] = [];

	groupedZonePrices: Array<{
		Name: string;
		ServicePrices: ISupplierZonePrice[];
	}> = [];

	@Watch('streamsFiltering.WasteStream')
	selectedContainerWasteStream(data: any) {
		if (!data) {
			this.streamsFiltering.WasteStream = new ContainerWasteStream();
		}

		this.streamsFiltering.ContainerType = new ContainerType();
		this.streamsFiltering.WasteSubStream = new ContainerWasteSubStream();
	}

	@Watch('streamsFiltering.WasteSubStream')
	selectedContainerWasteSubStream(data: any) {
		if (!data) {
			this.streamsFiltering.WasteSubStream = new ContainerWasteSubStream();
		}

		this.selectedContainerUnit(null);
	}

	@Watch('streamsFiltering.ContainerType')
	async selectedContainerType(data: any) {
		if (!data) {
			this.streamsFiltering.ContainerType = new ContainerType();
		}
		this.streamsFiltering.ContainerSize = new ContainerSize();
	}

	@Watch('streamsFiltering.ContainerSize')
	async selectedContainerSize(data: any) {
		if (!data) {
			this.streamsFiltering.ContainerSize = new ContainerSize();
		}
		this.streamsFiltering.ContainerUnit = new ContainerUnit();
	}

	@Watch('streamsFiltering.ContainerUnit')
	async selectedContainerUnit(data: any) {
		if (!data) {
			this.streamsFiltering.ContainerUnit = new ContainerUnit();
		}

		if (
			this.streamsFiltering.ContainerType.Id ||
			this.streamsFiltering.WasteSubStream.Id
		) {
			this.filteredServices = await this.supplierZoneServicesService.GetServiceFromServiceDetails(
				this.streamsFiltering.WasteStream.Id,
				this.streamsFiltering.ContainerType.Id,
				this.streamsFiltering.WasteSubStream.Id,
				this.streamsFiltering.ContainerSize.Id,
				this.streamsFiltering.ContainerUnit.Id
			);
		} else {
			this.filteredServices = [];
		}
	}

	async created() {
		this.supplierZonePriceForm.ZoneId = Number(this.$route.params.zoneId);

		this.supplierZoneServicesService
			.GetSupplierZonePricesGrouped(Number(this.$route.params.zoneId))
			.then(data => {
				this.groupedZonePrices = data;
				if (data.length > 0) {
					this.selectedPrices = this.groupedZonePrices[0].ServicePrices;
				}
				this.isLoading = false;
			});

		this.containerInfo = await this.supplierZoneServicesService.GetContainerInfo();
	}

	InitializeForm() {
		this.showCreateForm = true;
		this.selectedPrices = [];
	}

	CloseServiceDetails() {
		if (
			this.supplierZonePriceForm &&
			this.supplierZonePriceForm.Id &&
			this.supplierZonePriceForm.Id > 0
		) {
			this.showCreateForm = false;
			this.selectedService = null;
		} else {
			this.selectedService = null;
		}
		this.supplierZonePriceForm = new SupplierZonePriceForm();
		(this.$refs.form as any).resetValidation();
	}

	CloseSelectWasteStream() {
		this.showCreateForm = false;
	}

	SubmitPriceService() {
		this.supplierZoneServicesService
			.SubmitPriceService(this.supplierZonePriceForm)
			.then((price: ISupplierZonePrice) => {
				const index = this.groupedZonePrices.findIndex(
					xx => xx.Name == this.selectedService?.ServiceTypeName
				);

				if (index !== -1) {
					const itemIndex = this.groupedZonePrices[
						index
					].ServicePrices.findIndex(xx => xx.Id === price.Id);
					if (itemIndex !== -1) {
						this.groupedZonePrices[index].ServicePrices[itemIndex] = price;
					} else {
						this.groupedZonePrices[index].ServicePrices.push(price);
					}
				} else {
					this.groupedZonePrices.push({
						Name: this.selectedService?.ServiceTypeName || 'Other',
						ServicePrices: [price],
					});
				}
				this.selectedService = null;
				this.selectedPrices = this.groupedZonePrices[
					index != -1 ? index : this.groupedZonePrices.length - 1
				].ServicePrices;
			});

		this.showCreateForm = false;
		this.selectedContainerWasteStream(null);
		this.filteredServices = [];
		this.supplierZonePriceForm = new SupplierZonePriceForm();
		(this.$refs.form as any).resetValidation();
	}

	ShowEditPriceForm(price: ISupplierZonePrice) {
		this.showCreateForm = true;
		this.selectedService = price.Service || null;
		this.supplierZonePriceForm = new SupplierZonePriceForm(price);
	}

	SelectService(service: IService) {
		this.selectedService = service;
		this.supplierZonePriceForm.ServiceId = service.Id;
	}

	Save() {
		ZonesModule.UpdateGroupedZonePrices(this.groupedZonePrices);
		this.$router.push(`/zones/${this.$route.params.zoneId}/surcharge`);
	}

	DisableContainer(containerArray: Array<any>) {
		return containerArray
			? containerArray.length === 0
				? true
				: false
			: false;
	}

	ShowSurchargeMenu(item: any, event: any) {
		item.ShowSurchargeModal = true;
		this.pricingItem = item;
		this.buttonEvent = event;
	}

	ShowPricingMenu(price: any, event: any) {
		price.ShowPricingModal = true;
		this.pricingItem = price;
		this.buttonEvent = event;
	}

	ConvertPriceToArray(price: ISupplierZonePrice): Array<any> {
		const arr = [];
		if (price.Lift) {
			arr.push({
				Name: 'Lift',
				Value: price.Lift,
			});
		}
		if (price.Haulage) {
			arr.push({
				Name: 'Haulage',
				Value: price.Haulage,
			});
		}

		if (price.Tonnage) {
			arr.push({
				Name: 'Tonnage',
				Value: price.Tonnage,
			});
		}

		if (price.OnDelivery) {
			arr.push({
				Name: 'On Delivery',
				Value: price.OnDelivery,
			});
		}

		if (price.OnDemand) {
			arr.push({
				Name: 'On Demand',
				Value: price.OnDemand,
			});
		}

		if (price.OverweightPricePerKg) {
			arr.push({
				Name: 'Overweight (Kg)',
				Value: price.OverweightPricePerKg,
			});
		}

		if (price.ExcessTonnage) {
			arr.push({
				Name: 'Excess (Ton)',
				Value: price.ExcessTonnage,
			});
		}

		if (price.MinTonnes) {
			arr.push({
				Name: 'Min Tonnes',
				Value: price.MinTonnes,
			});
		}

		if (price.MaxTonnes) {
			arr.push({
				Name: 'Max Tonnes',
				Value: price.MaxTonnes,
			});
		}

		return arr;
	}

	onScroll() {
		if (this.buttonEvent) {
			this.buttonEvent.srcElement.click();
			this.buttonEvent = null;
		}
	}
}
