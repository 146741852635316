import { DropDownType } from '@/classes/generics/classes/DropDownType';
import { ISupplierZonePrice } from '../ISupplierZonePrice';

export class SupplierZonePriceForm {
	public Id?: number;
	public ZoneId: number | undefined | null = 0;
	public ServiceId: number | undefined | null = 0;
	public Lift: number | undefined | null = undefined;
	public Haulage: number | undefined | null = undefined;
	public Tonnage: number | undefined | null = undefined;
	public Rental: number | undefined | null = undefined;
	public OnDemand: number | undefined | null = undefined;
	public OnDelivery: number | undefined | null = undefined;
	public POA: boolean | undefined | null = false;

	public MinTonnes: number | undefined | null = undefined;
	public MaxTonnes: number | undefined | null = undefined;
	public OverweightPricePerKg: number | undefined | null = undefined;
	public ExcessTonnage: number | undefined | null = undefined;

	public Frequencies: number | undefined | null = 0;
	public Days: number | undefined | null = 0;

	private _frequenciesSelect: Array<DropDownType> = [];
	get FrequenciesSelect(): Array<DropDownType> {
		return this._frequenciesSelect;
	}
	set FrequenciesSelect(value: Array<DropDownType>) {
		this._frequenciesSelect = value;

		this.Frequencies = this._frequenciesSelect.reduce(
			(sum, current) => sum + current.Value,
			0
		);
	}

	private _daysSelect: Array<DropDownType> = [];
	get DaysSelect(): Array<DropDownType> {
		return this._daysSelect;
	}
	set DaysSelect(value: Array<DropDownType>) {
		this._daysSelect = value;

		this.Days = this._daysSelect.reduce(
			(sum, current) => sum + current.Value,
			0
		);
	}

	constructor(price: ISupplierZonePrice | null = null) {
		if (price) {
			this.Id = price.Id;
			this.ZoneId = price.ZoneId;
			this.ServiceId = price.ServiceId;
			this.Lift = price.Lift;
			this.Haulage = price.Haulage;
			this.OnDelivery = price.OnDelivery;
			this.OnDemand = price.OnDemand;
			this.POA = price.POA;
			this.Rental = price.Rental;
			this.DaysSelect = price.DaysDropDown;
			this.FrequenciesSelect = price.FrequenciesDropDown;
			this.Tonnage = price.Tonnage;
			this.Frequencies = price.Frequencies;
			this.Days = price.Days;

			this.ExcessTonnage = price.ExcessTonnage;
			this.OverweightPricePerKg = price.OverweightPricePerKg;
			this.MinTonnes = price.MinTonnes;
			this.MaxTonnes = price.MaxTonnes;
		}
	}
}
